@charset "utf-8";

// Our variables
// $base-font-family: 'Segoe UI Semilight', 'Segoe UI', 'Segoe', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
// $base-font-size:   16px;
// $base-font-weight: 300;
// $small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Minima also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// Import partials from the `minima` theme.
@import "minima";
