/**
 * Site header
 */


.site-header {
  padding-top: $spacing-unit;
  position: relative;
  text-align: center;
  font-weight: 100;
}

.site-title:visited, .site-title:hover, .site-title {
  font-size: 250%;
  color: $title-color;
  text-decoration: none;
}

.site-description {
  color: $title-color;
}

.site-searchbutton {
  color: $grey-color-light;
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
  height: 20px;
  width: 300px;
  margin: auto;
  cursor: pointer;
}

.site-searchbutton:hover {
  background-color: $background-color-heavy;
}


.site-searchbar {
  background-color: transparent;
  width: 300px;
  font-size: 100%;
  text-align: center;
  color: $text-color;
  border: 1pt solid $grey-color;
  display: none;
}

.previous:hover, .next:hover {
  text-decoration: none;
}

sup {
  line-height: 0;
}

/*
** Mermaid
*/

pre, code{
  color:inherit !important;
  background-color: transparent;
  font-family: "Fira Code", monospace;
  border: 0;
}

.edgeLabel {
  background-color: white !important;
}

pre.language-graph > div {
  display: none;
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
  > ul {
    list-style: none;
    text-align: right;
  }
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
.page-content {
  padding: 0;
}

.page-heading {
  font-size: 100%;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    width:100%;
    display: table;
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
    border-bottom: 1pt solid $grey-color-light;

    > a {
      display: table-cell;
      text-decoration: none;
      color: $text-color;
      vertical-align: middle;
    }
  }

  > li:hover {
      background-color: $background-color-heavy;
      cursor: pointer;
  }
}

.post-meta {
  font-size: $small-font-size;
  text-align: right;
}

.post-link {
  display: block;
  font-size: 24px;
}



/**
 * Posts
 */
.post-header {
  margin-top: 120px;
  margin-bottom: 120px;
  @include media-query($on-laptop) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.post-title {
  text-align: center;
  font-size: 225%;
  font-family: $base-font-family;
  line-height: 1;
}

.post {
  max-width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.post-content {
  margin-bottom: $spacing-unit;
  counter-reset: h1;

  h1::before {
    counter-increment: h1;
    content: counter(h1) " ";
    margin-right: 15px;
  }
  
  h1 {
    padding-top: 32px;
    padding-bottom: 10px;
    font-size: 32px;
    counter-reset: h2;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h2::before {
    counter-increment: h2;
    content: counter(h1) "." counter(h2) " ";
    margin-right: 15px;
  }

  h2 {
    font-size: 22px;
    counter-reset: h3;

    @include media-query($on-laptop) {
      font-size: 20px;
    }
  }

  h3::before {
    counter-increment: h3;
    content: counter(h1) "." counter(h2) "." counter(h3) " " ;
    margin-right: 15px;
  }

  h3 {
    font-size: 18px;

    @include media-query($on-laptop) {
      font-size: 16px;
    }
  }
}

.post-related {
  margin-top: 200px;

  @include media-query($on-laptop) {
    margin-top: 3 * $spacing-unit;
  }
  border-top: 1pt solid $grey-color;

  h1 {
    color: $grey-color;
  }
}

.pagination {
  margin: 10px;
  text-align: center;
}

.deactivated {
  color: $grey-color-light;
}

.site-footer {
  margin-top: 50px;
  @include media-query($on-laptop) {
    margin-top: 0;
  }
  .icon {
    display: inline-block;
    width: 18px;
    margin-right: 6px;
  }
}
