// Define defaults for each variable.
@import url('/assets/fonts/FiraCode/fira_code.css');
@import url('/assets/fonts/cmuserif/cmun-serif.css');
@import url('/assets/fonts/Spectral/spectral.css');

// @import url('/assets/fonts/Inter UI/inter-ui.css');
// @import url('/assets/fonts/cmuserif_roman/stylesheet.css');
// @import url('/assets/fonts/cmuserif_bold/stylesheet.css');

// "Computer Modern Serif", "cmu_serifroman",
$base-font-family: spectral, Trebuchet, Times, Arial, serif !default;
$base-bold-font-family: $base-font-family;
$base-font-size:   1.4em;
$base-font-weight: 200;
$base-font-space: 0px;
$small-font-size:  87.5%;
$large-font-size:  150%;
$xlarge-font-size: 190%;
$base-line-height: 1.5;

$spacing-unit:     20px;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$background-color-heavy: #ddd;
$brand-color:      $text-color;
$title-color:      #d8d8d8;
$highlight-color:  #111;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    900px;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
